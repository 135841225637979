import React from 'react';
import { observer } from 'mobx-react-lite';
import { AboutScalesViewModel } from './AboutScalesViewModel';
import { Image, Container, Card, Embed, Segment, SegmentInline, Grid } from 'semantic-ui-react';
import Image1 from '../../assets/About/Image1.jpg'
import Image2 from '../../assets/About/Image2.png'
import Image3 from '../../assets/About/Image3.png'
import Image4 from '../../assets/About/Image4.png'
import Image5 from '../../assets/About/Image5.png'
import Image6 from '../../assets/About/Image6.png'
import Image7 from '../../assets/About/Image7.png'
import Image8 from '../../assets/About/Image8.png'
import Image9 from '../../assets/About/Image9.png'
import Image10 from '../../assets/About/Image10.png'
import Image11 from '../../assets/About/Image11.png'
import Image12 from '../../assets/About/Image12.png'
import Image13 from '../../assets/About/Image13.png'
import Image14 from '../../assets/About/Image14.png'
import Image15 from '../../assets/About/Image15.png'
import Image16 from '../../assets/About/Image16.png'
import Image17 from '../../assets/About/Image17.png'
import Image18 from '../../assets/About/Image18.png'
import ImgSettings from '../../assets/About/ImgSettingsBtn.png'

import ImagePlcHldr from '../../assets/About/placeholder.jpg'
interface AboutScalesProps {
    viewModel: AboutScalesViewModel;
}

const AboutScales: React.FC = () => {

    return (
        <Container>
            {/*<Segment color='black' inverted>*/}
            {/*<Segment >*/}
            {/*    В личном кабинете пользователя при нажатии кнопки с именем пользователя выскакивает подменю, в котором можно выбрать два пункта, либо выйти из кабинета пользователя, либо перейти в профиль(режим настроек):*/}

            {/*    </Segment>*/}
            {/*    <Image src={Image8} size='medium' />*/}

            {/*<Segment>*/}
            {/*    После выбора Меню Профиль мы попадаем на следующую страницу:*/}
            {/*</Segment>*/}
            {/*    <Image src={Image9} size='medium' />*/}

            {/*<Segment>*/}
            {/*    На данном этапе пользователь может сменить пароль от личного кабинета, подключить личный кабинет к Вашему Telegram аккаунту на телефоне, после подключения данной функции, оповещения, которые приходят от весов будут приходить в Ваш аккаунт Telegram.*/}
            {/*        Так же мы видим здесь Ваши весы (их название). Здесь можно зайти в настройки Ваших весов, нажав кнопочку настроек.*/}

            {/*</Segment>*/}
            {/*    <Image src={Image10} size='medium' />*/}
            {/*<Segment>*/}
            {/*    Сверху в окне пользователь может видеть номер своих весов, версию прошивки весов(fw) и версию прошивки файловой системы(fs).*/}
            {/*        На данном этапе, пользователь может изменять названия улья, под которым стоят весы, относить весы, если у Вас они не одни к разным пасекам? Изменять цвет графика, период отправки данных (задается в минутах), выбирать режим отправки. Доступно 3 режима: 1. GSM (отправка данных с помощью мобильного оператора (необходима сим-карта) в личный кабинет пользователя; 2. Wi-Fi (отправка данных через беспроводное соединение) в личный кабинет пользователя; 3. SMS (отправка с помощью мобильного оператора (необходима сим-карта) SMS сообщения на телефон, указанный в настройках весов.*/}

                
            {/*</Segment>*/}
            {/*    <Image src={Image11} size='medium' />*/}
            {/*<Segment>*/}
            {/*    Можно выставлять число попыток отправки данных. Необходимо понимать, что весы стоят в самом низу, практически у земли и обычно сигнал оператора сотовой связи там всегда меньше, чем у Вас на мобильном телефоне, поэтому отправить данные весы могут не с первого раза, рекомендуемое значение от 3 до 5. Если и за это время весы не отправляют данные, то необходимо переместить весы в другое место, так как сигнала мобильного оператора недостаточно для отправки данных через GSM.*/}
            {/*        Уведомление о разряде батареи позволяет пользователю заранее узнать, что на весах садится аккумулятор. Оповещение придет в виде SMS, если весы работают по GSM, либо в Telegram, если весы работаю по Wi-Fi. Внимание, с весами в комплекте идет солнечная батарея, которая практически позволяет не обращать внимания на заряд аккумуляторов весь сезон.*/}
            {/*        Контроль выхода роя позволяет Вам контролировать резкое изменение веса на Ваших весах. Очень полезная функция. Оповещения приходят точно так же, как и при разряде батареи, описанном выше.*/}
            {/*        Важно!!! Все оповещения приходят с периодичностью периода отправки данных, указанных выше, кроме GSM сигнализации.*/}
            {/*        Если включена функция GSM сигнализации, при открывании крышки улья, весы незамедлительно оповещают владельца весов об этом. Датчик открытия крышки улья идет в комплекте с весами.*/}
            {/*        Если нажать на Опции связи перед Вами откроется следующее меню.*/}

                
            {/*</Segment>*/}
            {/*    <Image src={Image12} size='medium' />*/}
            {/*<Segment>*/}
            {/*    Здесь пользователь может задать свой номер телефона, изменить название весов, для подключения к ним по Wi-Fi, изменить пароль доступа к весам.*/}
            {/*        Так же если пользователь выбирает режим работы весов по Wi-Fi, здесь пользователь вводит точное название его Wi-Fi сети, к которой весы будут каждый раз подключаться для отправки данных и пароль от этой Wi-Fi сети.*/}
                
            {/*</Segment>*/}
            {/*    <Image src={Image13} size='medium' />*/}
            {/*<Segment>*/}
            {/*    В весах предусмотрена возможность отвязки весов от личного кабинета и перепривязки их в другой личный кабинет(если пчеловод решит перепродать весы другому пчеловоду).*/}
            {/*        Теперь пройдемся по другому меню, отвечающему за показ графиков.*/}
            {/*        Если нажать в левом углу на выпадающее меню (три черточки), то мы увидим там пункты: На главную, О весах и График.*/}

                
            {/*</Segment>*/}
            {/*    <Image src={Image14} size='medium' />*/}
            {/*<Segment>*/}
            {/*    Выбрав пункт график, нам откроется страница с графиком привеса веса в кг, за сегодняшний день*/}


                
            {/*</Segment>*/}
            {/*    <Image src={Image15} size='medium' />*/}
            {/*<Segment>*/}
            {/*    Внизу по оси Х мы видим время или даты, а по оси Y привесы за это время.*/}
            {/*        Так же мы видим, что доступны еще настройки, нажав иконку в виде шестеренки, мы попадем в меню, в котором можем редактировать информацию, выводимую в выпадающем окне, при нажатии в любую точку графика.*/}

                
            {/*</Segment>*/}
            {/*    <Image src={Image16} size='medium' />*/}
            {/*<Segment>*/}
            {/*    График можно увеличивать или уменьшать. Увеличение позволяет увидеть все точки, а если точнее, то количество принятых данных в личном кабинете пользователя.*/}
                
            {/*</Segment>*/}
            {/*    <Image src={Image17} size='medium' />*/}
            {/*<Segment>*/}
            {/*    Все уведомления, которые отправляют весы непосредственно пользователю, также дублируются и на графике в виде красных перевернутых треугольников. Если нажать на любую точку, например, на треугольник, мы увидим все данные, которые у нас указаны в настройках для показа, а также внизу само уведомление, позволяющее понять, что происходило с весами.*/}
                
            {/*</Segment>*/}
            {/*    <Image src={Image18} size='medium' />*/}

            {/*<Segment>*/}
            {/*    Более подробная информация по графикам, настройке и работе весов смотрите в наших видео.*/}
                
            {/*</Segment>*/}

            {/*</Segment>*/}

            <Card.Group
                itemsPerRow={3}
                stackable
            >

                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Card.Description style={{ color: 'white' }}>В личном кабинете пользователя при нажатии кнопки с именем пользователя выскакивает подменю, в котором можно выбрать два пункта, либо выйти из кабинета пользователя, либо перейти в профиль(режим настроек):</Card.Description>
                        <Image src={Image8} size='medium' />

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Card.Description style={{ color: 'white' }}>После выбора Меню Профиль мы попадаем на следующую страницу:</Card.Description>
                        <Image src={Image9} size='medium' />
                        <Card.Description style={{ color: 'white' }}>На данном этапе пользователь может сменить пароль от личного кабинета, подключить личный кабинет к Вашему Telegram аккаунту на телефоне, после подключения данной функции, оповещения, которые приходят от весов будут приходить в Ваш аккаунт Telegram.</Card.Description>
                        <Card.Description style={{ color: 'white' }}>Так же мы видим здесь Ваши весы (их название). Здесь можно зайти в настройки Ваших весов, нажав кнопочку <Image src={ImgSettings} size='mini' /> - "редактировать"</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image10} size='medium' />
                        
                        <Card.Description style={{ color: 'white' }}>Сверху в окне пользователь может видеть номер своих весов, версию прошивки весов(fw) и версию прошивки файловой системы(fs).
                            На данном этапе, пользователь может изменять названия улья, под которым стоят весы, относить весы, если у Вас они не одни к разным пасекам? Изменять цвет графика, период отправки данных (задается в минутах), выбирать режим отправки. Доступно 3 режима: 1. GSM (отправка данных с помощью мобильного оператора (необходима сим-карта) в личный кабинет пользователя; 2. Wi-Fi (отправка данных через беспроводное соединение) в личный кабинет пользователя; 3. SMS (отправка с помощью мобильного оператора (необходима сим-карта) SMS сообщения на телефон, указанный в настройках весов.
</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image11} />
                       
                        <Card.Description style={{ color: 'white' }}>Можно выставлять число попыток отправки данных. Необходимо понимать, что весы стоят в самом низу, практически у земли и обычно сигнал оператора сотовой связи там всегда меньше, чем у Вас на мобильном телефоне, поэтому отправить данные весы могут не с первого раза, рекомендуемое значение от 3 до 5. Если и за это время весы не отправляют данные, то необходимо переместить весы в другое место, так как сигнала мобильного оператора недостаточно для отправки данных через GSM.
                            Уведомление о разряде батареи позволяет пользователю заранее узнать, что на весах садится аккумулятор. Оповещение придет в виде SMS, если весы работают по GSM, либо в Telegram, если весы работаю по Wi-Fi. Внимание, с весами в комплекте идет солнечная батарея, которая практически позволяет не обращать внимания на заряд аккумуляторов весь сезон.
                            Контроль выхода роя позволяет Вам контролировать резкое изменение веса на Ваших весах. Очень полезная функция. Оповещения приходят точно так же, как и при разряде батареи, описанном выше.
                            Важно!!! Все оповещения приходят с периодичностью периода отправки данных, указанных выше, кроме GSM сигнализации.
                            Если включена функция GSM сигнализации, при открывании крышки улья, весы незамедлительно оповещают владельца весов об этом. Датчик открытия крышки улья идет в комплекте с весами.
</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Card.Description style={{ color: 'white' }}>Если нажать на Опции связи перед Вами откроется следующее меню.</Card.Description>
                        <Image src={Image12} />

                        <Card.Description style={{ color: 'white' }}>Здесь пользователь может задать свой номер телефона, изменить название весов, для подключения к ним по Wi-Fi, изменить пароль доступа к весам.
                            Так же если пользователь выбирает режим работы весов по Wi-Fi, здесь пользователь вводит точное название его Wi-Fi сети, к которой весы будут каждый раз подключаться для отправки данных и пароль от этой Wi-Fi сети.
</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image13} />
                        
                        <Card.Description style={{ color: 'white' }}>В весах предусмотрена возможность отвязки весов от личного кабинета и перепривязки их в другой личный кабинет(если пчеловод решит перепродать весы другому пчеловоду).</Card.Description>

                    </Card.Content>
                </Card>
                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Card.Description style={{ color: 'white' }}>Теперь пройдемся по другому меню, отвечающему за показ графиков.
                            Если нажать в левом углу на выпадающее меню (три черточки), то мы увидим там пункты: На главную, О весах и График.
</Card.Description>
                        <Image src={Image14} />
                        
                        <Card.Description style={{ color: 'white' }}>Выбрав пункт график, нам откроется страница с графиком привеса веса в кг, за сегодняшний день</Card.Description>

                    </Card.Content>
                </Card>
                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Image src={Image15} />
                       
                        <Card.Description style={{ color: 'white' }}>Внизу по оси Х мы видим время или даты, а по оси Y привесы за это время.
</Card.Description>

                    </Card.Content>
                </Card>
                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Image src={Image16} />
                        
                        <Card.Description style={{ color: 'white' }}>Так же мы видим, что доступны еще настройки, нажав иконку в виде шестеренки, мы попадем в меню, в котором можем редактировать информацию, выводимую в выпадающем окне, при нажатии в любую точку графика..</Card.Description>

                    </Card.Content>
                </Card>
                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Image src={Image17} />
                        
                        <Card.Description style={{ color: 'white' }}>График можно увеличивать или уменьшать. Увеличение позволяет увидеть все точки, а если точнее, то количество принятых данных в личном кабинете пользователя.</Card.Description>

                    </Card.Content>
                </Card>
                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Image src={Image18} />
                        
                        <Card.Description style={{ color: 'white' }}>Все уведомления, которые отправляют весы непосредственно пользователю, также дублируются и на графике в виде красных перевернутых треугольников. Если нажать на любую точку, например, на треугольник, мы увидим все данные, которые у нас указаны в настройках для показа, а также внизу само уведомление, позволяющее понять, что происходило с весами. Более подробная информация по графикам, настройке и работе весов смотрите в наших видео.</Card.Description>

                    </Card.Content>
                </Card>
            </Card.Group>
            <Card fluid style={{ background: 'black' }}>

                <Image src={Image1}></Image>
            </Card>

            <Segment color='black' inverted>
                <Grid columns={2} stackable textAlign='center'>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Card fluid>
                                <Card.Content>
                                    <Embed autoplay={true}
                                        source='youtube'
                                        id='1vQsLKxZuig'
                                        placeholder={ImagePlcHldr} />
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column>
                            <p style={{ textAlign: 'justify' }}>
                                Пасечные весы для пчеловодов BeeHoney.by - то, что
                                обязательно должно быть на любой пасеке, для понимания, что происходит с их пчелками.
                                Весы могут работать по сети GSM(Нужна сим-карта), WiFi, а так же через SMS(нужна сим-карта).
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                Данные отправляются на сайт( в личный кабинет, в котором можно смотреть вес, температуру, влажность, давление, заряд батареи,
                                мощность сигнала), а при работе через SMS - непосредственно на телефон владельца.
                                Вы всегда понимаете, есть ли в природе взяток. Если выйдет рой, весы оповестят Вас звонком, либо, если не дозвонятся - отправят SMS.
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                Так же присутствует GSM сигнализация. При открывании крышки улья незамедлительно
                                происходит оповщение владельца об этом инциденте.
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                В комплекте поставляется солнечная батарея,
                                которая позволяет не заряжать аккумуляторы весь сезон.
                            </p>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Segment>

            <Card.Group
                itemsPerRow={3}
                stackable
            >

                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Image src={Image2} />
                        <Card.Header style={{ color: 'white' }} >Металлическая платформа.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>Использование прочной металлической платформы позволяет быстро установить весы под улей.</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image3} />
                        <Card.Header style={{ color: 'white' }}>Блок аккумуляторов и зарядки.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>В одном корпусе находятся и аккумуляторы и блок зарядки, что позволяет либо поменять аккумуляторы на месте, либо их зарядить дома или в автомобиле.</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image4} />
                        <Card.Header style={{ color: 'white' }}>Блок управления.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>В блоке управления находится вся электроника. В нем также находится и датчики давления и температуры системы.</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image5} />
                        <Card.Header style={{ color: 'white' }}>Датчик влажности.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>Датчик влажности позволяет понять был ли дождь на пасеке.</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image6} />
                        <Card.Header style={{ color: 'white' }}>Датчик веса.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>В весах используется 4 датчика веса в мостовой схеме включения. Общая допустимая нагрузка равна 200 кг.</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image7} />
                        <Card.Header style={{ color: 'white' }}>Датчик температуры.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>Позволяет понимать какая температура в данный момент на пасеке и анализировать влияние температуры на работу ваших пчёл.</Card.Description>

                    </Card.Content>
                </Card>

            </Card.Group>

            

        </Container>

    );
}

export default observer(AboutScales);