import { observer } from "mobx-react-lite";
import React from "react";
import { FC } from "react";
import { RoundButton } from "../../styledElements/styledButtons";
import CancelButtonViewModel from "./CancelButtonViewModel";



interface CancelButtonProps {
    onClick?: () => void;
    maxHeight?: string;
    maxWidth?: string;
    size?: string;
    fill?: string;
    position?: string;
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    border?: string;
}

const CancelButton: FC<CancelButtonProps> = ({ onClick, maxHeight, maxWidth, size, fill, position, top, right, bottom, left, border }) => {
    const model = new CancelButtonViewModel();
    model.setOnClick(onClick);

    return (
        <RoundButton onClick={model.OnClick} position={position} size={size} top={top} right={right} fill={fill||'red'}>
            <svg width="2em" height="2em" viewBox="-3 -3 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="11"
                    x2="11" y2="1"
                    stroke="#000"
                    strokeWidth="2" />
                <line x1="1" y1="1"
                    x2="11" y2="11"
                    stroke="#000"
                    strokeWidth="2" />
            </svg>
        </RoundButton>
    );
}

export default observer(CancelButton);