import { observer } from "mobx-react-lite";
import React from "react";
import { FC } from "react";
import { RoundButton } from "../../styledElements/styledButtons";
import AcceptButtonViewModel from "./AcceptButtonViewModel";



interface AcceptButtonProps {
    onClick?: () => void;
    maxHeight?: string;
    maxWidth?: string;
    size?: string;
    fill?: string;
    position?: string;
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    border?: string;
}

const AcceptButton: FC<AcceptButtonProps> = ({ onClick, maxHeight, maxWidth, size, fill, position, top, right, bottom, left, border }) => {
    const model = new AcceptButtonViewModel();
    model.setOnClick(onClick);

    return (
        <RoundButton onClick={model.OnClick} size={size} fill={fill || 'green'} >
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24" >
                <path d="M7.8,21.425A2.542,2.542,0,0,1,6,20.679L.439,15.121,2.561,13,7.8,18.239,21.439,4.6l2.122,2.121L9.6,20.679A2.542,2.542,0,0,1,7.8,21.425Z" />
            </svg>
        </RoundButton>
    );
}

export default observer(AcceptButton);